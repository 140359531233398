import GenericClass from "@/models/generic-class";

export default class KidsSection extends GenericClass {
	idKidsSection; // number;
	name; // string;
	tags; // Array<KidsTag>:
	color; // string

	constructor(idKidsSection, name, tags, color) {
		super();
		this.idKidsSection = idKidsSection;
		this.name = name;
		this.tags = tags;
		this.color = color;
	}
}
