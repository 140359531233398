import GenericClass from "@/models/generic-class";

export default class KidsTag extends GenericClass {
	idKidsTag; // number;
	name; // string;
	idTags; // Array<string>:

	constructor(idKidsTag, name, idTags) {
		super();
		this.idKidsTag = idKidsTag;
		this.name = name;
		this.idTags = idTags;
	}
}
